import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { useContext, useEffect, useState } from "react"

import "./style.scss"
import AppContext from "../../Context"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import { PlanItem } from "./components/plan-item"
import { fetchPlanRequest } from "../../actions/plan"
import { LoadingIndicator } from "../../components/LoadingIndicator"

const Plan = () => {
  const dispatch = useDispatch()
  const { setContext } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(true)

  const plans = useSelector((state) => {
    return state.plan.plans
  })
  const profile = useSelector((state) => {
    return state.auth.profile
  })

  useEffect(() => {
    getPlans()
  }, [])

  const getPlans = () => {
    dispatch(
      fetchPlanRequest({
        onSuccess: (response) => {
          setIsLoading(false)
          setContext("overlay", null)
        },
        onFailed: () => {
          setIsLoading(false)
          setContext("overlay", null)
        }
      })
    )
  }

  /*Remaining date function */
  const date1 = new Date(
    moment(profile.contract?.end_datetime).format("YYYY-MM-DD")
  )
  const date2 = new Date(moment().format("YYYY-MM-DD"))

  const diffInMilliseconds = date1 - date2
  const day_left = Math.floor(diffInMilliseconds / 86400000)

  return (
    <Layout>
      <AppBar title="購入" withBack />
      <div className="bg-white d-flex flex-column full-height-with-navbar overflow-hidden h-100">
        <div className="container pt-3 pb-5 plans-page">
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <div className="main-content">
              <p className="mb-2 text-note-plan">
                男性会員様は多数のグレードをご用意しております。
                <br />
                無料会員様はメッセージ、通話はできません。
              </p>
              {plans?.title && (
                <>
                  <div className="plans-title">{plans?.title[0]}</div>
                  <div className="plans">
                    {plans?.plans?.plan_1.map((item) => (
                      <PlanItem
                        key={item.plan_id}
                        plan={item}
                        day_left={day_left}
                        profile={profile}
                      />
                    ))}
                  </div>

                  <p className="mb-2 text-note-plan">
                    プラチナ,ダイヤモンド,モノリス会員様は
                    <br />
                    すべての機能がご利用いただけます。
                    <br />
                    また、ランクごとに女性から見える表示が豪華になります。
                  </p>
                  <p className="mb-2 text-note-plan">
                    ※通常プランからプランをアップデートする場合、残日数を日割りで計算してのご請求金額になります。プラン期限はそのままご利用いただけます。
                  </p>
                  <div className="plans-title">{plans?.title[1]}</div>
                  <div className="plans">
                    {plans?.plans?.plan_2.map((item) => (
                      <PlanItem
                        key={item.plan_id}
                        plan={item}
                        day_left={day_left}
                        profile={profile}
                      />
                    ))}
                  </div>

                  <div className="plans-title">{plans?.title[2]}</div>
                  <div className="plans">
                    {plans?.plans?.plan_3.map((item) => (
                      <PlanItem
                        key={item.plan_id}
                        plan={item}
                        day_left={day_left}
                        profile={profile}
                      />
                    ))}
                  </div>

                  <div className="plans-title">{plans?.title[3]}</div>
                  <p className="mb-0">
                    上限10名様のプランです。
                    <br /> 現在 {plans.sumMonolith}名様が購入しました。
                  </p>
                  <div className="plans">
                    {plans?.plans?.plan_4.map((item) => (
                      <PlanItem
                        key={item.plan_id}
                        plan={item}
                        disabled={plans.sumMonolith >= 10}
                        day_left={day_left}
                        profile={profile}
                      />
                    ))}
                  </div>
                </>
              )}
              <div>
                <p>
                  ※自動継続について
                  <br />
                  ・自動継続課金は、所持しているすべてのプランの期限が切れる24時間以内に自動的に課金される仕組みです。
                  <br />
                  ・最後に自動継続に対応したお支払い方法で購入されたプランに自動継続されます。
                  <br />
                  ・「会員プラン有効期限日」より前に自動継続を解除しなければ、購読プランは自動的に更新されます。
                  <br />
                  ・所持プランと有効期限は「マイページ >
                  会員プラン」でご確認いただけます。
                  <br />
                  ・更新日前に退会のお手続きをされた場合、残りの期間についての月割り・日割りでの返金はいたしません。
                  <br />
                  ・「マイページ >
                  会員プラン」のページにございます「自動更新」をオフにすると、自動継続を停止することが可能です。
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default Plan
