import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Modal } from "react-bootstrap"
import { fetchPopupsRequest } from "../../actions/popups"
import { useLocation } from "react-router-dom"
import { Image, Button } from "react-bootstrap"
import htmlParser from "react-html-parser"

const CustomPopups = ({ filteredData }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { data } = useSelector((state) => state.popups)
  const [isModalDismissed, setIsModalDismissed] = useState(localStorage.getItem('modalDismissed') === 'true')
  
  useEffect(() => {
    dispatch(fetchPopupsRequest())
  }, [dispatch])

  useEffect(() => {
    if (!filteredData || isModalDismissed) return null
  }, [filteredData, isModalDismissed])

  const handleDismiss = () => {
    localStorage.setItem('modalDismissed', 'true')
    setIsModalDismissed(true)
  }

  const handleAccept = () => {
    localStorage.setItem('modalDismissed', 'true')
    setIsModalDismissed(true)
    window.open(filteredData.button_url, '_blank')
  }

  return (
    <Modal show={!isModalDismissed} centered>
      <Modal.Body className="p-0">
        <Image className="image" src={filteredData?.image_url} />
        <div className="py-3">
          <p className="my-3 text-center px-3">{htmlParser(filteredData?.content)}</p>
          <div className="d-grid justify-content-center gap-2 mt-3">
            <Button
              onClick={handleAccept}
              variant="danger"
              size="lg"
              className="btn fs-6 btn-update-schedule"
              style={{ width: 150 }}
            >
              {filteredData.button_text}
            </Button>
            <button
              className="btn fs-6 primary"
              style={{ width: 150 }}
              onClick={handleDismiss}
            >
              編集決定
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default CustomPopups