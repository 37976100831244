import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TabPanel } from "react-headless-tabs"
import {
  getUnreadMatchCountRequested,
  getUnreadMessageCountRequested
} from "../../actions/message"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import { MatchedList } from "./components/listMessage/List"
import { useHistory } from "react-router-dom"

import "./styles.scss"

const Chats = () => {
  const dispatch = useDispatch()
  const [bannerHeight, setBannerHeight] = useState(0)

  const currentUserId = useSelector((state) => state.auth.profile.user_id)

  const history = useHistory()

  useEffect(() => {
    dispatch(getUnreadMatchCountRequested(currentUserId, dispatch))
    dispatch(getUnreadMessageCountRequested(currentUserId, dispatch))
  }, [])

  return (
    <Layout nav>
      <AppBar
        title="トーク"
        search
        placeholder=" "
        banner
        setBannerHeight={setBannerHeight}
      />
      <div
        className="bg-white d-flex flex-column full-height-with-navbar wrap-message-list"
        style={{ top: bannerHeight + 90 }}
      >
        <div className="h-100">
          <div className="h-100">
            <TabPanel className="h-100">
              <MatchedList valueSearch={history.location.state?.values} />
            </TabPanel>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Chats
