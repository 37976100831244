import PropTypes from "prop-types"
import classNames from "classnames"
import { useEffect, useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"

import "./styles.scss"
import Nav from "../Nav"
import { getLikersRequested } from "../../actions/likes"
import { getUnreadMessageCountRequested, setUnreadMessageNumber, getChatListRequested } from "../../actions/message"
import { observeFadeInElements } from '../../utils/scrollObserver';
import { fetchPopupsRequest } from "../../actions/popups"

const Layout = ({ flex, type, children, nav, className, height = true }) => {
  const dispatch = useDispatch()
  const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 });
  const scrollDemoRef = useRef(null);
  const currentUserId = useSelector((state) => state.auth.profile.user_id);
  const users = useSelector((state) => state.message.chatList);

  useEffect(() => {
    dispatch(fetchPopupsRequest())
  }, [dispatch])

  const handleScroll = () => {
    if (scrollDemoRef.current) {
      const { scrollTop, scrollLeft } = scrollDemoRef.current;
      setScrollPosition({ scrollTop, scrollLeft });
    }
  };

  useEffect(() => {
    let count = 0;
    users.forEach((user) => {
      if (conditionRead(user, currentUserId) === '#FFE3E0') {
        count++;
      }
    });
    dispatch(setUnreadMessageNumber(count));
  }, [users, currentUserId, dispatch]);

  const conditionRead = (user, currentUserId) => {
    if (user.lastMessage?.userId === currentUserId) {
      return 'white';
    }
    if (user.isMatchingRead === 1 && user.lastMessage?.isRead === 1) {
      return 'white';
    }
    return '#FFE3E0';
  };

  useEffect(() => {
    dispatch(getChatListRequested(currentUserId, dispatch));
  }, [currentUserId, dispatch]);

  useEffect(() => {
    const currentRef = scrollDemoRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
  }, [scrollPosition]);

  useEffect(() => {
    observeFadeInElements();
  }, []);

  const [item1, setItem1] = useState({
    to: "/users?login=true",
    icon: 1,
    label: "一覧",
    number: 0
  })

  const [item2, setItem2] = useState({
    to: "/schedules",
    icon: 2,
    label: "今からどう？",
    number: 0
  })

  const [item3, setItem3] = useState({
    to: "/chats",
    icon: 4,
    label: "トーク",
    number: 0
  })

  const [item4, setItem4] = useState({
    to: "/likes",
    icon: 4,
    label: "気になる",
    number: 0
  })

  const [item5, setItem5] = useState({
    to: "/profile",
    icon: 5,
    label: "マイページ",
    number: 0
  })

  const [items, setItems] = useState([])

  const ref = useRef(currentUserId)

  const unreadMessageCount = useSelector(
    (state) => state.message.unreadMessageCount
  )

  const likerListCount = useSelector((state) => state.likes.likers.length)

  useEffect(() => {
    if (ref.current !== currentUserId && currentUserId) {
      ref.current = currentUserId
      dispatch(getLikersRequested(currentUserId, dispatch))
      dispatch(getUnreadMessageCountRequested(currentUserId, dispatch))
    }
  }, [currentUserId])

  useEffect(() => {
    setItem4({
      to: "/likes",
      icon: 4,
      label: "気になる",
      number: likerListCount
    })
  }, [likerListCount])

  useEffect(() => {
    setItem3({
      to: "/chats",
      icon: 3,
      label: "トーク",
      number: unreadMessageCount
    })
  }, [unreadMessageCount])

  useEffect(() => {
    setItems([item1, item2, item3, item4, item5])
  }, [item1, item5, item2, item3, item4])

  return (
    <div
      className={classNames(
        `${className} layout-${type} ${height ? "height-vh-100" : ""} `,
        {
          "d-flex": flex,
          [`flex-${flex}`]: flex,
          "with-nav": nav
        }
      )}
      ref={scrollDemoRef}
      onScroll={handleScroll}
    >
      {children}
      {nav && <Nav items={items} />}
    </div>
  )
}

Layout.propTypes = {
  type: PropTypes.string,
  flex: PropTypes.string,
  nav: PropTypes.bool
}

Layout.defaultProps = {
  type: "default",
  flex: null,
  nav: false
}

export default Layout
